<template>
<div>
<van-nav-bar
      class="backBox"
      title="服务变更续费"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="box_wrap">
        <div class="inner_box">
          <div class="box">
            <div class="box_header">
              <span class="type_name"
                >{{serveInfo.name}}/{{serveInfo.monthMoney}}元</span
              >
              <!-- <span class="type_name" v-if="serveInfo.type == 2"
                >工作日白天包月卡/300元</span
              >
              <span class="type_name" v-if="serveInfo.type == 3"
                >夜间包月卡/150元</span
              > -->
              <span class="type_date">{{ serveInfo.deadLine }} 到期</span>
            </div>
            <!-- <span class="btn_text" @click="openRenew">续费</span> -->
            <div class="group_5">
              <!-- <span class="text_5" v-if="serveInfo.type == 1 && payNum == 1"
                >{{ monthTypeList[0].value }}元</span
              > -->
              <!-- <span class="text_5" v-if="serveInfo.type == 2 && payNum == 1"
                >{{ monthTypeList[1].value }}元</span
              >
              <span class="text_5" v-if="serveInfo.type == 3 && payNum == 1"
                >{{ monthTypeList[2].value }}元</span
              > -->
              <span class="text_5">{{ payMoney.toFixed(2) }}元</span>
              <!-- 计算后金额{{}} -->
              <div class="group_6">
                <div class="section_2">
                  <div class="text-wrapper_1">
                    <van-stepper v-model="payNum" @change="onChangeStep" />
                  </div>
                </div>
                <span class="text_8">月</span>
              </div>
            </div>
          </div>

          <div class="lineline"></div>
          <div class="tip_box">
            <span class="tip_box_tit">温馨提示：</span>
            <span class="tip_box_text"
              >1.套餐显示为单个车位的价格，支付总金额按照本次办理的约束<br />或者周数叠加计算</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="pay_button"> -->
      <van-button
        class="pay_button"
        type="info"
        color="##4470FC"
        @click="onClickPay"
        >变更</van-button
      >
      <!-- </div> -->
    </div>
    <!-- 绑定手机 -->
    <bind-phone
      :show="show_bind_phone"
      @get-success="getBindPhoneSuccess"
      @get-on-close="getBindPhoneShow"
    ></bind-phone>
    <!-- 未授权或者未绑定手机的提示弹窗 -->
    <not-auth-or-phone
      :show="showPhonePopupInNotIndex"
      :show-type="showNotAuthPhoneType"
      @get-on-close="getNotAuthBindPhoneShow"
      @showBindPhone="showBindPhone"
    ></not-auth-or-phone>
  </div>
  </div>
</template>
<script>
import checkPhoneMixin from '../../../mixins/checkPhone'
import BindPhone from '../../../components/BindPhone.vue'
import NotAuthOrPhone from '../../../components/NotAuthOrPhone.vue'
export default {
  mixins: [checkPhoneMixin],
  components: { BindPhone, NotAuthOrPhone },
  data () {
    return {
      serveInfo: {
        // 预留 获取当前服务信息 单价等
        // type: 1,
        // type: 2,
        type: 3,
        name: '测试停车月卡',
        deadLine: '2099-12-31 23:59:59',
        monthMoney: 10

      },
      columnsSel: '',
      showVhPopup: false,
      columns: [
        '浙A12345',
        '浙B12345',
        '浙C12345',
        '浙D12345',
        '浙F12345'
      ],
      showPhonePopupInNotIndex: false,
      show_bind_phone: false,
      showNotAuthPhoneType: 2,
      payMoney: 0,
      payNum: 1,
      monthTypeList: [
        {
          id: 1,
          value: 1,
          name: '无限制包月卡',
          date: '全时段'

        },
        {
          id: 2,
          value: 2,
          name: '工作日白天包月卡',
          date: '工作日（周一至周五）08:00至17:00'
        },
        {
          id: 3,
          value: 99,
          name: '夜间包月卡',
          date: '22:00至次日09:00'
        }
      ]

    }
  },
  mounted () {
    // 每个页面都要 引入下面的混入
    // 监听混入中触发的事件，显示弹窗
    this.$on('showPhonePopup', () => {
      this.showPhonePopupInNotIndex = true
    })
    // ToDo 监听混入中触发的事件，手机号已绑定 则关闭打开的弹窗
    this.$on('phoneHaveBind', () => {
      if (this.show_bind_phone) {
        this.show_bind_phone = false
      }
      if (this.showPhonePopupInNotIndex) {
        this.showPhonePopupInNotIndex = false
      }
    })

    if (this.serveInfo && this.serveInfo.monthMoney) {
      this.payMoney = this.serveInfo.monthMoney
    }
  },
  computed: {

  },
  methods: {
    onChangeStep () {
      console.log('数量', this.payNum)
      console.log('单价', this.serveInfo.monthMoney)
      this.payMoney = this.payNum * this.serveInfo.monthMoney
    },
    getBindPhoneSuccess () {

    },
    getBindPhoneShow () {

    },
    getNotAuthBindPhoneShow () {

    },
    showBindPhone () {
      this.show_bind_phone = true
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 续费
    openRenew () {
      this.$router.push('/monthlyRental/serviceChangeRenew')
    },
    showPopup () {
      this.showVhPopup = true
    },
    onClickPay () {

    },
    onVhChange () {

    },
    onVhConfirm (data) {
      console.log('选择车牌', data)
      if (data) {
        this.columnsSel = data
        this.showVhPopup = false
      }
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    onVhClose () {
      this.showVhPopup = false
    }

  }
}
</script>
<style lang="scss" scoped>
.page {

}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  // padding: 1rem;
  .box_wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    .inner_box {
      padding: 0.16rem;
      background-color: #fff;

      .box {
        width: 3.43rem;
        margin-right: 0.01rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .box_header {
          display: flex;
          flex-direction: column;
          .title {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: .16rem;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-right: 0.51rem;
          }
          .type_name {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-top: 0.16rem;
          }
          .type_date {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.12rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.17rem;
            margin-top: 0.02rem;
          }
        }
        .group_5 {
          display: flex;
          flex-direction: column;
          .text_5 {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: right;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-left: 0.18rem;
          }
          .group_6 {
            margin-top: 0.09rem;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .section_2 {
              margin-bottom: 0.02rem;
              display: flex;
              flex-direction: column;
              .text-wrapper_1 {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                margin: 0 0.03rem 0 0.09rem;
              }
            }
            .text_8 {
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 0.14rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.2rem;
            }
          }
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
          margin-top: 0.36rem;
        }
      }
      .lineline {
        width: 3.43rem;
        height: 0.01rem;
        background-color: #f4f4f4;
        margin-top: 0.16rem;
      }
      .vh_box {
        width: 3.43rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.16rem 0.01rem 0 0;
        .type_name {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
      .tip_box {
        display: flex;
        flex-direction: column;
        margin: 0.25rem 0.12rem 0 0;
        .tip_box_tit {
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
          margin-right: 2.77rem;
        }
        .tip_box_text {
          width: 3.32rem;
          height: 0.34rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
          margin-top: 0.08rem;
          margin-bottom: 0.24rem;
        }
      }
    }
  }
  .pay_button {
    width: 90%;
    border-radius: 0.04rem;
  }
}
</style>
